<template>
<div class="auth flex flex-col md:flex-row p-4  items-center ">
    <div class="right_part">
      <h1 class="color_white mb-6" style="font-size: 40px;">Criar nova senha</h1>
      <div class="box edit_box w-full">
        <form @submit.prevent="submit">
          <div class="form-group flex flex-col mb-4">  
            <span for="password" class="mb-2 font-bold">Senha</span>
            <input id="password" type="password" class="form-control" v-model="form.new_password" placeholder="Senha">
            <FieldError :invalidFields="invalidFields" name="password" />
            <div class="flex gap-1">
              <div class="pass-strength" :class="{ 'active' : passwordStrength >= 1 }"></div>
              <div class="pass-strength" :class="{ 'active' : passwordStrength >= 2 }"></div>
              <div class="pass-strength" :class="{ 'active' : passwordStrength >= 3 }"></div>
              <div class="pass-strength" :class="{ 'active' : passwordStrength >= 4 }"></div>
            </div>
          </div>
          <div class="form-group flex flex-col mb-4">  
            <span for="password" class="mb-2 font-bold">Repita a senha</span>
            <input id="password" type="password" class="form-control" v-model="form.new_password_confirmation" placeholder="Senha">
            <FieldError :invalidFields="invalidFields" name="password_confirmation" />
          </div>
          <div v-if="this.message">
            <p class="text-red mb-2">{{ this.message }}</p>
          </div>
          <div>
            <button type="submit" class="btn btn-grad">Cadastrar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import FieldError from '@/components/FieldError'

export default {
  components: { FieldError },
  props: ['token'],
  data() {
    return {
      invalidFields: [],
      message: false,
      form: {
        new_password: '',
        new_password_confirmation: ''
      }
    }
  },
  computed: {
    passwordStrength() {
      let strength = 0
      if (this.form.new_password.length > 6) {
        strength++
      }
      if (/[A-Z]/.test(this.form.new_password)) {
        strength++
      }
      if (/[a-z]/.test(this.form.new_password)) {
        strength++
      }
      if (/[^0-9a-zA-Z]/.test(this.form.new_password)) {
        strength++
      }

      return strength
    }
  },
  methods: {
    async submit() {
      this.message = false
      let form = this.form 

      if (this.$route.params.token) {
        form = { ...this.form, token : this.$route.params.token }
      }
      const response = await api.post('user/new-password', form)
      if (!response.errors) { 
        this.message = 'Senha alterada com sucesso!'
      } else {
        this.message = response.message
      }
    },
    formIsValid() {
      let valid = true
      this.invalidFields = []
      
      Object.keys(this.form).map(field => {
        if(this.form[field] === '') {
          this.invalidFields = [...this.invalidFields, `${field}:Este campo não pode ser vazio.`]
          valid = false
        }
      })

      return valid
    }
  }
}
</script>

<style scoped>
  .pass-strength {
    margin-top: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: rgba(255,255,255,0.3);
    flex: 1;
  }

  .pass-strength.active:first-child {
    background: #f50000;
  }
  .pass-strength.active:nth-child(2) {
    background: #f59e00;
  }
  .pass-strength.active:nth-child(3) {
    background: #cff500;
  }
  .pass-strength.active:nth-child(4) {
    background: rgb(0,255,0);
  }
</style>